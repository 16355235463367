@import "./src/assets/styles/index.scss";

.news {
    width: 100%;
    box-sizing: border-box;

    div {
        box-sizing: border-box;
    }

    &-video {
        background: rgb(128, 128, 128);

        &-body {
            width: var(--body-width);
            height: 100%;
            padding: var(--pading-video);
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            position: relative;

            >video {
                width: 100%;
            }

            >span {
                position: absolute;
                display: flex;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                background: rgba(255, 255, 255, .5);
                box-shadow: 0 1px 5px rgba(0, 0, 0, .5);
            }
        }
    }

    &-body {
        width: var(--body-width);
        padding: 120px 0;
        margin: auto;
        text-indent: 2em;
        display: flex;

        &-video {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 70%;
            
        }

        &-text {
            width: 30%;
            padding-left: 60px;
        }

    }
}
